
let env = 'dev'; //测试环境
// let env = 'prod'; //正式环境

let baseUrl: string = '';
let cdn: string = '';
let cdns: string = '';

switch (env) {
    case 'dev':
        baseUrl = 'http://data.plat.xgf365.com';
        cdn = 'http://cdns.zldhome.com/';
        cdns = 'https://cdns.zldhome.com/';
        break;
    case 'prod':
        baseUrl = 'http://data.plat.xgf365.com';
        cdn = 'https://saasfile.cdslhd.com/';
        cdns = 'https://saasfile.cdslhd.com/';
        break;
}

const website = '';

export default {
    website,
    baseUrl,
    cdn,
    cdns
}