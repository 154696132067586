
const module: string = 'plat';

export default [
    {
        name: `${module}_businessType`,
        component: import('@/views/plat/BusinessType.vue')
    },
    {
        name: `${module}_merchants`,
        component: import('@/views/plat/Merchants.vue')
    },
    {
        name: `${module}_stores`,
        component: import('@/views/plat/Stores.vue')
    },
    {
        name: `${module}_templateMess`,
        component: import('@/views/plat/TemplateMess.vue')
    },
    {
        name: `${module}_micro`,
        component: import('@/views/plat/Micro.vue')
    },
    {
        name: `${module}_serviceVersion`,
        component: import('@/views/plat/ServiceVersion.vue')
    }
]