import {BaseRequest} from "@/request/BaseRequest";

class Common extends BaseRequest {

    public getTyCodePath: string = '/commOpen/micro/experienceQRCode';

    /**
     * 获取七牛token
     */
    getQiNiuToken(): any {
        return this.request({
            url: '/commTools/qiniu_key',
            method: 'get'
        })
    }

    /**
     * 商户类型列表
     */
    businessTypeList(): any {
        return this.request({
            url: '/commTools/business/list',
            method: 'get'
        })
    }

    /**
     * 所有省
     */
    provinceList(): any {
        return this.request({
            url: '/commTools/province/list',
            method: 'get'
        })
    }

    /**
     * 市
     * @param provinceId
     */
    cityList(provinceId: string): any {
        return this.request({
            url: `/commTools/city/list?provinceId=${provinceId}`,
            method: 'get'
        })
    }

    /**
     * 获取小程序模版
     */
    getMpTemplateList(): any {
        return this.get('/commTools/codeTemplate/list');
    }

    /**
     * 添加小程序时，获取小程序类型
     */
    getSelfMpTemplateList(): any {
        return this.get('/commOpen/micro/templateTypeList');
    }

}

const c = new Common();
export {c as Common}