
const module: string = 'webDomain';

export default [
    {
        name: `${module}_consultCustomer`,
        component: import('@/views/webDomain/ConsultCustomer.vue')
    },
    {
        name: `${module}_dynamicMess`,
        component: import('@/views/webDomain/DynamicMess.vue')
    },
    {
        name: `${module}_keywords`,
        component: import('@/views/webDomain/KeyWords.vue')
    }
]