import Vue from 'vue'
import Router from 'vue-router'
import ViewUI from 'view-design';
import {title} from '@/common/Title'
Vue.use(Router);

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/Index.vue'),
        // children: children
    },
    {
        path: '/result_auth',
        meta: {
            title: '授权结果'
        },
        component: () => import('@/views/open/ResultAuth.vue')
    },
    {
        path: '/register_micro@*',
        meta: {
            title: '快速注册小程序'
        },
        component: () => import('@/views/open/RegisterMicro.vue')
    }
];

const router = new Router({
    mode: 'history',
    routes: routes
});

router.beforeEach((to, from, next) => {
    // @ts-ignore
    ViewUI.LoadingBar.start();
    // title(to.meta.title);
    next()
});

router.afterEach(() => {
    // @ts-ignore
    ViewUI.LoadingBar.finish();
    window.scrollTo(0, 0)
});

export default router
